@font-face {
  font-family: 'FuturaPTBook';
  src: local('FuturaPTBook'), url(./assets/fonts/FuturaPTBook.otf) format('opentype');
}

@font-face {
  font-family: 'FuturaPTDemi';
  src: local('FuturaPTDemi'), url(./assets/fonts/FuturaPTDemi.otf) format('opentype');
}

@font-face {
  font-family: 'FuturaPTMedium';
  src: local('FuturaPTMedium'), url(./assets/fonts/FuturaPTMedium.otf) format('opentype');
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "FuturaPTBook", sans-serif;
}

h1 {
  font-family: "FuturaPTDemi", sans-serif;
}

h1 span {
  font-family: "FuturaPTDemi", sans-serif;
  color: #CC8A05;
}

h2 {
  font-family: "FuturaPTMedium", sans-serif;
  font-size: 17px;
  text-transform: uppercase;
}

button {
  border: none;
  border-radius: 5px;
  outline: none;
  width: 100%;
  padding: 8px 0;
  color: #fff;
  font-size: 16px;
  letter-spacing: 1px;
  background: #CC8A05;
  cursor: pointer;

}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: rgb(86, 87, 86);
  color: white;
  text-decoration: none;
}

li {
  text-decoration: none;
}

.nav-links {
  width: 40%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  text-align: center;
  text-decoration: none;
}

.bold {
  font-weight: bold;
}

.highlight {
  color: #CC8A05;
}

.link {
  cursor: pointer;
  text-decoration: none;
}

.center {
  text-align: "center";
}

.logo {
  padding-top: 50px;
  padding-bottom: 30px;
  max-width: 100px;
}

.goPlay {
  max-width: 200px;
}

.login {
  width: 100%;
  min-height: 80vh;
  /* padding: 0 20px; */
  background: #e9e9e9;
  display: flex;
}

.login .loginContainer {
  padding: 60px;
  margin: auto;
  width: 100%;
  max-width: 450px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  border-radius: 5px;
}

.login .loginContainer label {
  color: #343A3F;
  margin: 14px 0;
  display: block;
  font-size: 22px;
  line-height: 1;
}

.login .loginContainer .textInput {
  width: 100%;
  border: none;
  border-bottom: solid;
  border-color: #afafaf;
  outline: none;
  font-size: 18px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.185);
  /* color: #fff; */
  letter-spacing: 1px;
}

.login .loginContainer .btnContainer {
  width: 100%;
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login .loginContainer .btnContainer h1 {
  margin: 14px 0 0 0;
  text-align: center;
  color: #343A3F;
  font-family: "FuturaPTDemi", sans-serif;
  font-size: 36px;
}

.login .loginContainer .btnContainer p {
  margin: 14px 0 0 0;
  text-align: center;
  color: #343A3F;
  font-size: 18px;
}

.login .loginContainer .btnContainer p span {
  color: #747A21;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-left: 5px;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

.login .loginContainer .btnContainer p span:hover {
  color: #CC8A05;
}

.login .loginContainer .errorMsg {
  color: #8C2804;
  font-size: 16px;
  padding-top: 5px;
}

.header {
  top: 0;
  position: fixed;
  width: 100%;
}

.hero {
  width: 100%;
  min-height: 15vh;
  background: #e9e9e9;
}

.hero nav {
  background: #fff;
  width: 100%;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 25px 10px -30px rgba(0, 0, 0, 0.5);
}

.hero nav img {
  max-width: 150px;
}

.hero nav h2 {
  color: #fff;
}

.hero .banner {
  width: 100%;
  padding: 5px;
  background: #747A21;
  color: white;
  text-align: center;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 25px 10px -30px rgba(0, 0, 0, 0.5);
}

.hero .banner p {
  padding-left: 10px;
}

.hero .banner span {
  letter-spacing: 0.5px;
  padding-right: 10px;
  cursor: pointer;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 18px;
}

.content {
  padding: 10px;
  padding-top: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 600px;

}

.footer {
  background-color: #747A21;
}

.home {
  background: #fff;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  box-shadow: 0 25px 10px -30px rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
}

.page-container {
  width: 100%;
  max-width: 1000px;
  padding: 20px;
  box-shadow: 0 25px 10px -30px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
}

.page-container-text {
  width: 100%;
  max-width: 1000px;
  padding: 20px;
  box-shadow: 0 25px 10px -30px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: auto;
  gap: 12px;

}

.page-container-text li{
  width: 100%;
  max-width: 1000px;
  padding-top: 20px;
  text-align: left;
  margin: auto;
  gap: 12px;
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.home p {
  max-width: 600px;
}

.home button {
  width: 200px;
  padding: 8px 0;
  background: #CC8A05;
  align-self: center;
}

.home .alt {
  width: 200px;
  padding: 8px 0;
  color: #343A3F;
  background: #dbdbdb;
  align-self: center;
}

table,
th,
td {
  padding: 10px;
  border-collapse: collapse;
}

.account {
  background: #fff;
  width: 100%;
  max-width: 800px;
  min-height: 80vh;
  padding: 30px;
  box-shadow: 0 25px 10px -30px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  line-height: 30px;
  margin: auto;
}

.account h1 {
  align-self: center;
}

.account button {
  width: 100px;
  padding: 8px 0;
  background: #CC8A05;
  align-self: center;
}

.purchase {
  background: #fff;
  width: 100%;
  max-width: 800px;
  padding: 30px;
  box-shadow: 0 25px 10px -30px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.birthday_div {
  display: inline-block;
}

.birthday_inputs_left {
  float: left;
  width: 27%;
  border: none;
  border-bottom: solid;
  border-color: #afafaf;
  outline: none;
  font-size: 18px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.185);
  /* color: #fff; */
  letter-spacing: 1px;
  margin-left: 12px;
}

.birthday_inputs_right {
  float: right;
  width: 27%;
  border: none;
  border-bottom: solid;
  border-color: #afafaf;
  outline: none;
  font-size: 18px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.185);
  /* color: #fff; */
  letter-spacing: 1px;
  margin-right: 10px;
}

.control {
  /* font-family: arial; */
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 10px;
}

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;

}

.control_indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border: 0px solid #000000;
  border-radius: 0px;
  margin-right: 10px;
}

.control:hover input~.control_indicator,
.control input:focus~.control_indicator {
  background: #cccccc;
}

.control input:checked~.control_indicator {
  background: #cc8a05;
}

.control:hover input:not([disabled]):checked~.control_indicator,
.control input:checked:focus~.control_indicator {
  background: #cc8a05;
}

.control input:disabled~.control_indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}

.control input:checked~.control_indicator:after {
  display: block;
}

.control-checkbox .control_indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.control-checkbox input:disabled~.control_indicator:after {
  border-color: #7b7b7b;
}

.control-checkbox .control_indicator::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 4.5rem;
  height: 4.5rem;
  margin-left: -1.3rem;
  margin-top: -1.3rem;
  background: #cc8a05;
  border-radius: 3rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none;
}

#card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}

#card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}

#payment-request-button {
  margin-bottom: 32px;
}

/* spinner/processing state; errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.container {
  flex: 1;
  background: '#fff';
  padding-left: 30;
  padding-right: 30;
  padding-bottom: 30
}

.layout {
  flex: 1;
  align-items: 'flex-start';
  justify-content: 'center';
  margin: 20;
  margin-bottom: 150;
}

.header-privacy {
  font-size: 24;
  font-family: 'FuturaPT-Demi';
  text-align: 'left';
  color: '#343A3F';
  margin-top: 20;
  margin-bottom: 10;
}

.subheader {
  font-size: 20;
  font-family: 'FuturaPT-Demi';
  text-align: 'left';
  color: '#343A3F';
  margin-top: 10;
  margin-bottom: 10;
}

.subsubheader {
  font-size: 18;
  font-family: 'FuturaPT-Demi';
  text-align: 'left';
  color: '#343A3F';
  margin-top: 10;
  margin-bottom: 5;
}

.header_text {
  font-size: 24;
  font-family: 'FuturaPT-Demi';
  text-align: 'center';
  color: '#343A3F';
  margin-bottom: 50;
}

.horizontal_rule {
  border-color: 'gray';
  border-width: 1;
  margin-bottom: 30;
  margin-top: 30
}

.subtext {
  font-size: 18;
  font-family: 'FuturaPT-Book';
}

.boldtext {
  font-weight: 600;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
  }

  .nav-links{
    width: 80%;
    min-height: 100px;
    line-height: 27px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    text-align: center;
    text-decoration: none;
  }
}

@keyframes s-ripple {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes s-ripple-dup {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.control-checkbox input+.control_indicator::before {
  animation: s-ripple 250ms ease-out;
}

.control-checkbox input:checked+.control_indicator::before {
  animation-name: s-ripple-dup;
}