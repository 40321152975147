.paymentDetails {
    display: block;
    width: 100%;
    max-width: 100.0rem;
    padding: 0;
    margin: 2rem auto;

}


.group {
    margin: 0 0 2rem;
}